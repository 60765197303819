import { gql, useMutation, MutationUpdaterFn } from '@apollo/client'
import { clientMarketPlace } from 'src/configs/apolloConfig'

export const CHANGE_BUSINESS_NAME = gql`
  mutation ChangeBusinessName($businessId: String!, $newName: String!) {
    changeBusinessName(businessId: $businessId, newName: $newName) {
      id
      name
    }
  }
`

export function useChangeBusinessName(
  onCompleted?: (data: any) => void,
  update?: MutationUpdaterFn
) {
  const [changeBusinessName, { data, error, loading }] = useMutation(
    CHANGE_BUSINESS_NAME,
    {
      update,
      onCompleted,
    }
  )
  return { changeBusinessName, data, error, loading }
}

export const SET_BUSINESS_DEFAULT_BANK_ACCOUNT = gql`
  mutation SetBusinessDefaultBankAccount(
    $businessId: String!
    $bankAccountId: Int!
  ) {
    setBusinessDefaultBankAccount(
      businessId: $businessId
      bankAccountId: $bankAccountId
    )
  }
`

export function useSetBusinessDefaultBankAccount(
  onCompleted?: (data: any) => void,
  update?: MutationUpdaterFn
) {
  const [setBusinessDefaultBankAccount, { data, error, loading }] = useMutation(
    SET_BUSINESS_DEFAULT_BANK_ACCOUNT,
    {
      update,
      onCompleted,
    }
  )
  return { setBusinessDefaultBankAccount, data, error, loading }
}

export const ACCEPT_TEMPLATE_BANK_ACCOUNT = gql`
  mutation AcceptTemplateBankAccount(
    $oldAccountId: Long!
    $newAccountId: Long!
  ) {
    acceptTemplateBankAccount(
      oldAccountId: $oldAccountId
      newAccountId: $newAccountId
    )
  }
`

export function useAcceptTemplateBankAccount(
  onCompleted?: (data: any) => void,
  update?: MutationUpdaterFn
) {
  const [acceptTemplateBankAccount, { data, error, loading }] = useMutation(
    ACCEPT_TEMPLATE_BANK_ACCOUNT,
    {
      client: clientMarketPlace,
      update,
      onCompleted,
    }
  )
  return { acceptTemplateBankAccount, data, error, loading }
}

export const SET_BUYER_BID_ACCESS = gql`
  mutation SetBuyerBidAccess($sellerId: String!, $bidOption: Byte!) {
    setBuyerBidAccess(sellerId: $sellerId, bidOption: $bidOption) {
      sellerId
    }
  }
`

export function useSetBuyerBidAccess(
  onCompleted?: (data: any) => void,
  onError?: (data: any) => void,
  update?: MutationUpdaterFn
) {
  const [setBuyerBidAccess, { data, error, loading }] = useMutation(
    SET_BUYER_BID_ACCESS,
    {
      update,
      onCompleted,
      onError,
    }
  )
  return { setBuyerBidAccess, data, error, loading }
}

export const REMOVE_BUYER_BID_ACCESS = gql`
  mutation RemoveBuyerBidAccess($buyerBidAccess: BuyerBidAccess!) {
    removeBuyerBidAccess(buyerBidAccess: $buyerBidAccess) {
      bidAccessOption
      buyersInList {
        buyerId
        buyerName
        comment
        id
        lastUpdated
        lastUpdatedUserName
      }
      buyersOutOfList {
        id
        businessType
        feePercent
        name
        status
      }
      sellerId
    }
  }
`

export function useRemoveBuyerBidAccess(
  onCompleted?: (data: any) => void,
  onError?: (data: any) => void,
  update?: MutationUpdaterFn
) {
  const [removeBuyerBidAccess, { data, error, loading }] = useMutation(
    REMOVE_BUYER_BID_ACCESS,
    {
      update,
      onCompleted,
      onError,
    }
  )
  return { removeBuyerBidAccess, data, error, loading }
}

export const ADD_BUYER_BID_ACCESS = gql`
  mutation AddBuyerBidAccess($buyerBidAccess: BuyerBidAccess!) {
    addBuyerBidAccess(buyerBidAccess: $buyerBidAccess) {
      buyersInList {
        buyerId
        buyerName
        comment
        id
        lastUpdated
        lastUpdatedUserName
      }
    }
  }
`

export function useAddBuyerBidAccess(
  onCompleted?: (data: any) => void,
  onError?: (data: any) => void,
  update?: MutationUpdaterFn
) {
  const [addBuyerBidAccess, { data, error, loading }] = useMutation(
    ADD_BUYER_BID_ACCESS,
    {
      update,
      onCompleted,
      onError,
    }
  )
  return { addBuyerBidAccess, data, error, loading }
}

export const ADD_NOTIFICATION_SUBSCRIPTION = gql`
  mutation AddNotificationSubscription($request: BusinessNotificationRequest!) {
    addNotificationSubscription(request: $request) {
      businessId
      emailAddress
      userDisplayName
      userEmailAddress
      userId
      notificationItems {
        eventLabel
      }
    }
  }
`

export function useAddNotificationSubscription(
  onCompleted?: (data: any) => void,
  update?: MutationUpdaterFn
) {
  const [addNotificationSubscription, { data, error, loading }] = useMutation(
    ADD_NOTIFICATION_SUBSCRIPTION,
    {
      update,
      onCompleted,
    }
  )
  return { addNotificationSubscription, data, error, loading }
}

export const REMOVE_NOTIFICATION_SUBSCRIPTION = gql`
  mutation RemoveNotificationSubscription(
    $request: BusinessNotificationRequest!
  ) {
    removeNotificationSubscription(request: $request) {
      businessId
      emailAddress
      userDisplayName
      userEmailAddress
      userId
      notificationItems {
        eventLabel
      }
    }
  }
`

export function useRemoveNotificationSubscription(
  onCompleted?: (data: any) => void,
  update?: MutationUpdaterFn
) {
  const [removeNotificationSubscription, { data, error, loading }] =
    useMutation(REMOVE_NOTIFICATION_SUBSCRIPTION, {
      update,
      onCompleted,
    })
  return { removeNotificationSubscription, data, error, loading }
}

export const UPDATE_NOTIFICATION_SUBSCRIPTION = gql`
  mutation UpdateNotificationSubscription(
    $request: UpdateNotificationRequest!
  ) {
    updateNotificationSubscription(request: $request) {
      eventType
      eventSection
      eventLabel
      eventTypeDescription
      subscribed
    }
  }
`

export function useUpdateNotificationSubscription(
  onCompleted?: (data: any) => void,
  update?: MutationUpdaterFn
) {
  const [updateNotificationSubscription, { data, error, loading }] =
    useMutation(UPDATE_NOTIFICATION_SUBSCRIPTION, {
      update,
      onCompleted,
    })
  return { updateNotificationSubscription, data, error, loading }
}

export const REMOVE_BUYER_PORTFOLIO_TYPE = gql`
  mutation DeleteBuyerPortfolioType($request: BuyerPortfolioTypeRequest!) {
    deleteBuyerPortfolioType(request: $request) {
      buyerPortfolioTypes {
        id
        typeName
      }
      availablePortfolioTypes {
        id
        typeName
      }
    }
  }
`

export function useRemoveBuyerPortfolioType(
  onCompleted?: (data: any) => void,
  update?: MutationUpdaterFn
) {
  const [removeBuyerPortfolioType, { data, error, loading }] = useMutation(
    REMOVE_BUYER_PORTFOLIO_TYPE,
    {
      update,
      onCompleted,
    }
  )
  return { removeBuyerPortfolioType, data, error, loading }
}

export const ADD_BUYER_PORTFOLIO_TYPE = gql`
  mutation AddBuyerPortfolioType($request: BuyerPortfolioTypeRequest!) {
    addBuyerPortfolioType(request: $request) {
      buyerPortfolioTypes {
        id
        typeName
      }
      availablePortfolioTypes {
        id
        typeName
      }
    }
  }
`

export function useAddBuyerPortfolioType(
  onCompleted?: (data: any) => void,
  update?: MutationUpdaterFn
) {
  const [addBuyerPortfolioType, { data, error, loading }] = useMutation(
    ADD_BUYER_PORTFOLIO_TYPE,
    {
      update,
      onCompleted,
    }
  )
  return { addBuyerPortfolioType, data, error, loading }
}

export const REMOVE_BUSINESS_ASSET_TYPE = gql`
  mutation DeleteBusinessAssetType($request: BusinessAssetTypeRequest!) {
    deleteBusinessAssetType(request: $request) {
      availableAssetTypes {
        id
        name
      }
      assetTypes {
        id
        name
      }
    }
  }
`

export function useRemoveBusinessAssetType(
  onCompleted?: (data: any) => void,
  update?: MutationUpdaterFn
) {
  const [removeBusinessAssetType, { data, error, loading }] = useMutation(
    REMOVE_BUSINESS_ASSET_TYPE,
    {
      update,
      onCompleted,
    }
  )
  return {
    removeBusinessAssetType,
    data,
    error,
    loading,
  }
}

export const ADD_BUSINESS_ASSET_TYPE = gql`
  mutation AddBusinessAssetType($request: BusinessAssetTypeRequest!) {
    addBusinessAssetType(request: $request) {
      availableAssetTypes {
        id
        name
      }
      assetTypes {
        id
        name
      }
    }
  }
`

export function useAddBusinessAssetType(
  onCompleted?: (data: any) => void,
  update?: MutationUpdaterFn
) {
  const [addBusinessAssetType, { data, error, loading }] = useMutation(
    ADD_BUSINESS_ASSET_TYPE,
    {
      update,
      onCompleted,
    }
  )
  return { addBusinessAssetType, data, error, loading }
}

export const DELETE_BUSINESS_EMAIL_NOTIFICATION = gql`
  mutation DeleteBusinessEmailNotification(
    $businessId: String
    $email: String!
  ) {
    deleteBusinessEmailNotification(businessId: $businessId, email: $email)
  }
`

export function useDeleteBusinessEmailNotifications(
  onCompleted?: (data: any) => void,
  update?: MutationUpdaterFn
) {
  const [deleteBusinessEmailNotifications, { data, error, loading }] =
    useMutation(DELETE_BUSINESS_EMAIL_NOTIFICATION, {
      update,
      onCompleted,
    })
  return { deleteBusinessEmailNotifications, data, error, loading }
}

export const REMOVE_DELIVERY_STRUCTURE = gql`
  mutation DeleteDeliveryStructure($request: DeliveryStructureRequest!) {
    deleteDeliveryStructure(request: $request) {
      availableDeliveryStructures {
        id
        name
      }
      deliveryStructures {
        id
        name
      }
    }
  }
`

export function useRemoveDeliveryStructure(
  onCompleted?: (data: any) => void,
  update?: MutationUpdaterFn
) {
  const [removeDeliveryStructure, { data, error, loading }] = useMutation(
    REMOVE_DELIVERY_STRUCTURE,
    {
      update,
      onCompleted,
    }
  )
  return { removeDeliveryStructure, data, error, loading }
}

export const ADD_DELIVERY_STRUCTURE = gql`
  mutation AddDeliveryStructure($request: DeliveryStructureRequest!) {
    addDeliveryStructure(request: $request) {
      availableDeliveryStructures {
        id
        name
      }
      deliveryStructures {
        id
        name
      }
    }
  }
`

export function useAddDeliveryStructure(
  onCompleted?: (data: any) => void,
  update?: MutationUpdaterFn
) {
  const [addDeliveryStructure, { data, error, loading }] = useMutation(
    ADD_DELIVERY_STRUCTURE,
    {
      update,
      onCompleted,
    }
  )
  return { addDeliveryStructure, data, error, loading }
}

export const REMOVE_OPERATIONAL_STRUCTURE = gql`
  mutation DeleteOperationalStructure($request: OperationalStructureRequest!) {
    deleteOperationalStructure(request: $request) {
      availableOperationalStructures {
        id
        name
      }
      operationalStructures {
        id
        name
      }
    }
  }
`

export function useRemoveOperationalStructure(
  onCompleted?: (data: any) => void,
  update?: MutationUpdaterFn
) {
  const [removeOperationalStructure, { data, error, loading }] = useMutation(
    REMOVE_OPERATIONAL_STRUCTURE,
    {
      update,
      onCompleted,
    }
  )
  return { removeOperationalStructure, data, error, loading }
}

export const ADD_OPERATIONAL_STRUCTURE = gql`
  mutation AddOperationalStructure($request: OperationalStructureRequest!) {
    addOperationalStructure(request: $request) {
      availableOperationalStructures {
        id
        name
      }
      operationalStructures {
        id
        name
      }
    }
  }
`

export function useAddOperationalStructure(
  onCompleted?: (data: any) => void,
  update?: MutationUpdaterFn
) {
  const [addOperationalStructure, { data, error, loading }] = useMutation(
    ADD_OPERATIONAL_STRUCTURE,
    {
      update,
      onCompleted,
    }
  )
  return { addOperationalStructure, data, error, loading }
}

export const ADD_OR_UPDATE_BUSINESS_SETTINGS = gql`
  mutation AddOrUpdateBusinessRecoverySettings(
    $request: BusinessRecoverySettingsRequest!
  ) {
    addOrUpdateBusinessRecoverySettings(request: $request) {
      id
      businessId
      contactLastName
      contactFirstName
      contactMobilePhone
      contactOfficePhone
      contactEmail
      isCreditor
      isVendor
    }
  }
`

export function useAddOrUpdateBusinessSettings(
  onCompleted?: (data: any) => void,
  update?: MutationUpdaterFn
) {
  const [addOrUpdateBusinessSettings, { data, error, loading }] = useMutation(
    ADD_OR_UPDATE_BUSINESS_SETTINGS,
    {
      update,
      onCompleted,
    }
  )
  return { addOrUpdateBusinessSettings, data, error, loading }
}

export const REMOVE_BUSINESS_OPERATING_PLACEMENT_STAGE = gql`
  mutation DeleteBusinessOperatingPlacementStage(
    $request: BusinessOperatingPlacementStagesRequest!
  ) {
    deleteBusinessOperatingPlacementStage(request: $request) {
      availableOperatingPlacementStages
      operatingPlacementStages
    }
  }
`

export function useRemoveBusinessOperatingPlacementStage(
  onCompleted?: (data: any) => void,
  update?: MutationUpdaterFn
) {
  const [removeBusinessOperatingPlacementStage, { data, error, loading }] =
    useMutation(REMOVE_BUSINESS_OPERATING_PLACEMENT_STAGE, {
      update,
      onCompleted,
    })
  return {
    removeBusinessOperatingPlacementStage,
    data,
    error,
    loading,
  }
}

export const ADD_BUSINESS_OPERATING_PLACEMENT_STAGE = gql`
  mutation AddBusinessOperatingPlacementStage(
    $request: BusinessOperatingPlacementStagesRequest!
  ) {
    addBusinessOperatingPlacementStage(request: $request) {
      availableOperatingPlacementStages
      operatingPlacementStages
    }
  }
`

export function useAddBusinessOperatingPlacementStage(
  onCompleted?: (data: any) => void,
  update?: MutationUpdaterFn
) {
  const [addBusinessOperatingPlacementStage, { data, error, loading }] =
    useMutation(ADD_BUSINESS_OPERATING_PLACEMENT_STAGE, {
      update,
      onCompleted,
    })
  return { addBusinessOperatingPlacementStage, data, error, loading }
}

export const CHANGE_BUSINESS_ASSET_TYPE_FEE = gql`
  mutation ChangeBusinessAssetTypeFee($request: BusinessAssetTypeFeeRequest!) {
    changeBusinessAssetTypeFee(request: $request)
  }
`

export function useChangeBusinessAssetTypeFee(
  onCompleted?: (data: any) => void,
  update?: MutationUpdaterFn
) {
  const [changeBusinessAssetTypeFee, { data, error, loading }] = useMutation(
    CHANGE_BUSINESS_ASSET_TYPE_FEE,
    {
      update,
      onCompleted,
    }
  )
  return { changeBusinessAssetTypeFee, data, error, loading }
}

export const DELETE_BUSINESS_OPERATING_STAGE_PLACEMENT_ASSET_TYPE = gql`
  mutation DeleteBusinessOperatingPlacementStageAssetType(
    $request: BusinessOperatingPlacementStageAssetTypeRequest!
  ) {
    deleteBusinessOperatingPlacementStageAssetType(request: $request) {
      availableAssetTypes {
        id
        name
      }
      assetTypes {
        id
        name
      }
    }
  }
`

export function useDeleteBusinessOperatingPlacementStageAssetType(
  onCompleted?: (data: any) => void,
  update?: MutationUpdaterFn
) {
  const [
    deleteBusinessOperatingPlacementStageAssetType,
    { data, error, loading },
  ] = useMutation(DELETE_BUSINESS_OPERATING_STAGE_PLACEMENT_ASSET_TYPE, {
    update,
    onCompleted,
  })
  return {
    deleteBusinessOperatingPlacementStageAssetType,
    data,
    error,
    loading,
  }
}

export const ADD_BUSINESS_OPERATING_STAGE_PLACEMENT_ASSET_TYPE = gql`
  mutation AddBusinessOperatingPlacementStageAssetType(
    $request: BusinessOperatingPlacementStageAssetTypeRequest!
  ) {
    addBusinessOperatingPlacementStageAssetType(request: $request) {
      availableAssetTypes {
        id
        name
      }
      assetTypes {
        id
        name
      }
    }
  }
`

export function useAddBusinessOperatingPlacementStageAssetType(
  onCompleted?: (data: any) => void,
  update?: MutationUpdaterFn
) {
  const [
    addBusinessOperatingPlacementStageAssetType,
    { data, error, loading },
  ] = useMutation(ADD_BUSINESS_OPERATING_STAGE_PLACEMENT_ASSET_TYPE, {
    update,
    onCompleted,
  })
  return { addBusinessOperatingPlacementStageAssetType, data, error, loading }
}
