import { httpClient } from '../../axios-wrapper/httpClient'
import * as urls from '../../urls/dataManager'
import * as types from '../../models/dataManager'
import { PortfolioType } from '../../models/portfolio'

export const getAllPortfolioTypes = async (): Promise<PortfolioType[]> => {
  const response = await httpClient.get(
    urls.DataManagerGetAllPortfolioTypes,
    {}
  )

  return response.data
}

export const getFileDefinitionByPortfolioType = async (
  portfolioTypeId: number | undefined
): Promise<types.ExportFileDefinition[]> => {
  const response = await httpClient.get(
    urls.DataManagerGetFileDefinitionByPortfolioType,
    {
      params: {
        portfolioTypeId,
      },
    }
  )

  return response.data
}
