import React, { useContext, useEffect, useState } from 'react'
import { Tabs, Tab, Box } from '@mui/material'
import Comment from 'src/components/Comment'
import { BusinessAgencyRequestType } from 'src/graphql/models/agency'
import { useQuery } from '@apollo/client'
import { GetClient } from 'src/graphql/models/clientProfiles'
import { GET_CLIENT } from 'src/graphql/operations/queries/clientProfiles'
import BusinessLicensesList from 'src/components/License/LicenseList'
import { TENANT_TYPE } from 'src/utils/constants'
import { AuthContext } from 'src/context/AuthenticationContext'
import BusinessOperatingPlacementStage from 'src/pages/BusinessManage/components/BusinessOperatingPlacementStage'
import BusinessOperatingPlacementStageAssetTypes from 'src/pages/BusinessManage/components/BusinessOperatingPlacementStageAssetTypes'
import WebHooksNotification from 'src/pages/WebHooks/WebHooksNotification'
import AgencyBusinessInformation from './AgencyBusinessInformation'
import BusinessDeliveryStructure from '../../BusinessManage/components/BusinessDeliveryStructure'
import BusinessRecoverySettings from '../../BusinessManage/components/BusinessRecoverySettings'
import { Theme } from '@mui/system'
import shadows from '@mui/material/styles/shadows'
import { makeStyles } from '@mui/styles'
import CustomerAuditInfo from 'src/components/Audit/CustomerAuditInfo'
import { useHistory, useLocation } from 'react-router-dom'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      style={{ width: '100%', padding: 8 }}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

TabPanel.defaultProps = {
  children: null,
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    boxShadow: shadows[1],
    display: 'flex',
    minHeight: 224,
  },
  tabs: {
    minWidth: 160,
    borderRight: `1px solid ${theme.palette.divider}`,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    textAlign: 'center',
  },
}))

interface AgencyBusinessTabProps {
  clientId: number
  agencyBusinessId?: string | any
  agencyData?: BusinessAgencyRequestType | any
  agencyLoading?: boolean
}
const AgencyBusinessTab: React.FC<AgencyBusinessTabProps> = ({
  clientId,
  agencyBusinessId,
  agencyData,
  agencyLoading,
}) => {
  const classes = useStyles()
  const [value, setValue] = useState(0)
  const { user } = useContext(AuthContext)
  const isInternal = user && user.profile[TENANT_TYPE] === 'internal'
  const { data: getClientData } = useQuery<{
    getClient: GetClient
  }>(GET_CLIENT, { variables: { clientId: Number(clientId) } })

  const location = useLocation()
  const history = useHistory()

  const showRecoveryTabs = !process.env.REACT_APP_HIDE_RECOVERY_TABS
  const indexSubtract = showRecoveryTabs ? 0 : 2
  const tabIndices: { [key: string]: number } = {
    'business-information': 0,
    'account-placement': 1,
    'asset-types': 2,
    'delivery-structure': showRecoveryTabs ? 3 : -1,
    'business-recovery-settings': showRecoveryTabs ? 4 : -1,
    licenses: 5 - indexSubtract,
    comments: 6 - indexSubtract,
    webhooks: 7 - indexSubtract,
    audit: 8 - indexSubtract,
  }
  useEffect(() => {
    const tabText =
      location.pathname.split('/').pop()?.replace('tab=', '')?.trim() ||
      'business-information'
    const tabIndex = tabIndices[tabText]
    if (tabIndex !== undefined) {
      setValue(tabIndex)
    } else {
      setValue(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
    const tabText =
      Object.keys(tabIndices).find((key) => tabIndices[key] === newValue) ||
      'business-information'

    const currentPathname = location.pathname
    const newSearch = `/tab=${tabText}`
    const newUrl = currentPathname.includes('tab=')
      ? currentPathname.replace(/\/tab=[^&]+/, newSearch)
      : currentPathname + newSearch
    history.push(newUrl)
  }

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Agency Business Profile"
        className={classes.tabs}
      >
        <Tab
          label="Business information
(Agency)"
          {...a11yProps(0)}
        />

        <Tab
          label="Account Placement"
          {...a11yProps(1)}
          disabled={!clientId || !agencyBusinessId}
        />

        <Tab
          label="Asset Types"
          {...a11yProps(2)}
          disabled={!clientId || !agencyBusinessId}
        />

        {showRecoveryTabs && (
          <Tab
            label="Delivery Structure"
            {...a11yProps(3)}
            disabled={!clientId || !agencyBusinessId}
          />
        )}

        {showRecoveryTabs && (
          <Tab
            label="Business Recovery Settings"
            {...a11yProps(4)}
            disabled={!clientId || !agencyBusinessId}
          />
        )}

        {isInternal && (
          <Tab
            label="Licenses"
            {...a11yProps(5 - indexSubtract)}
            disabled={!clientId || !agencyBusinessId}
          />
        )}
        {isInternal && (
          <Tab
            label="Comments"
            {...a11yProps(6 - indexSubtract)}
            disabled={!clientId || !agencyBusinessId}
          />
        )}
        {isInternal && (
          <Tab
            label="Webhooks"
            disabled={!clientId || !agencyBusinessId}
            {...a11yProps(7 - indexSubtract)}
          />
        )}
        {isInternal && (
          <Tab
            label="Audit"
            {...a11yProps(8 - indexSubtract)}
            disabled={!agencyBusinessId}
          />
        )}
        {/* <Tab
          label="Branches"
          {...a11yProps(6 - indexSubtract)}
          disabled={!clientId || !agencyBusinessId}
        /> */}
      </Tabs>
      <TabPanel value={value} index={0}>
        <AgencyBusinessInformation
          clientId={clientId}
          agencyBusinessId={agencyBusinessId}
          agencyData={agencyData}
          client={getClientData?.getClient}
          agencyLoading={agencyLoading}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        {agencyBusinessId && (
          <BusinessOperatingPlacementStage businessId={agencyBusinessId} />
        )}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {agencyBusinessId && (
          <BusinessOperatingPlacementStageAssetTypes
            businessId={agencyBusinessId}
            inputAppend={true}
            placementStage={true}
          />
        )}
      </TabPanel>
      {showRecoveryTabs && (
        <TabPanel value={value} index={3}>
          {agencyBusinessId && (
            <BusinessDeliveryStructure businessId={agencyBusinessId} />
          )}
        </TabPanel>
      )}
      {showRecoveryTabs && (
        <TabPanel value={value} index={4}>
          {agencyBusinessId && (
            <BusinessRecoverySettings
              businessId={agencyBusinessId}
              client={getClientData?.getClient}
            />
          )}
        </TabPanel>
      )}
      {isInternal && (
        <>
          <TabPanel value={value} index={5 - indexSubtract}>
            {agencyBusinessId && (
              <BusinessLicensesList
                businessId={agencyBusinessId}
                clientCountry={getClientData?.getClient.countryCode}
                businessCountry={agencyData?.countryCode}
              />
            )}
          </TabPanel>
          <TabPanel value={value} index={6 - indexSubtract}>
            {clientId && agencyBusinessId && (
              <Comment clientId={clientId} businessId={agencyBusinessId} />
            )}
          </TabPanel>
          <TabPanel value={value} index={7 - indexSubtract}>
            <WebHooksNotification sellerBusinessId={agencyBusinessId} />
          </TabPanel>
          <TabPanel value={value} index={8 - indexSubtract}>
            {!!agencyBusinessId && (
              <CustomerAuditInfo id={agencyBusinessId} tableName="business" />
            )}
          </TabPanel>
        </>
      )}
      {/* <TabPanel value={value} index={6 - indexSubtract}>
        {agencyBusinessId && <Branch businessId={agencyBusinessId} />}
      </TabPanel> */}
    </div>
  )
}

AgencyBusinessTab.defaultProps = {
  agencyBusinessId: null,
  agencyData: null,
  agencyLoading: false,
}

export default AgencyBusinessTab
