import React, { useState } from 'react'
import {
  Box,
  FormControlLabel,
  Grid,
  MenuItem,
  Skeleton,
  Switch,
  TextField,
} from '@mui/material'
import { State } from '@progress/kendo-data-query'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import {
  DataTable,
  ModalDialog,
  Typography,
  renderCheckbox,
} from 'everchain-uilibrary'
import {
  addOrRemoveFileUnmaskWithColumns,
  getSellerUploadTemplateFileUnmaskBySeller,
} from 'src/infra/api/services/fileUnmask'
import {
  FileUnmaskColumnsRequest,
  FileUnmaskWithColumnsRequest,
  SellerUploadTemplateFileUnmaskDataResponse,
} from 'src/infra/api/models/fileUnmask'
import { getPortfolioTemplateFileMap } from 'src/infra/api/services/portfolio'
import { PortfolioTemplateFileMapResponse } from 'src/infra/api/models/portfolio'
import { notistackOptions } from 'src/configs/notistackOptions'
import { enqueueSnackbar } from 'notistack'
import { useQueryClient } from '@tanstack/react-query'
import { ExportFileDefinition } from 'src/infra/api/models/dataManager'
import {
  getAllPortfolioTypes,
  getFileDefinitionByPortfolioType,
} from 'src/infra/api/services/dataManager'
import { PortfolioType } from 'src/graphql/models/buyer'

interface FileUnmaskListProps {
  sellerId?: string
}

const FileUnmaskList: React.FC<FileUnmaskListProps> = ({ sellerId }) => {
  const [openEditModal, setOpenEditModal] = useState<boolean>(false)
  const [editTemplateId, setEditTemplateId] = useState<number>()
  const [portfolioTypeId, setPortfolioTypeId] = useState<number>()
  const [fileDefinitionId, setFileDefinitionId] = useState<number>()
  const [columnsRequest, setColumnsRequest] = useState<
    FileUnmaskColumnsRequest[]
  >([])
  const [unmaskWithColumnsRequest, setUnmaskWithColumnsRequest] =
    useState<FileUnmaskWithColumnsRequest>()
  const [unmaksEntireFile, setUnmaksEntireFile] = useState<boolean>(false)
  const [gridState, setGridState] = useState<State>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  })
  const gridStateEdit = {
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  }

  const gridColumns: any[] = [
    {
      field: 'sellerUploadTemplateId',
      title: 'Template ID',
      width: 180,
      show: true,
    },
    {
      field: 'sellerUploadTemplateName',
      title: 'Template Name',
      width: 180,
      show: true,
    },
    {
      field: '',
      title: 'Unmasked Columns',
      width: 180,
      show: true,
      render: (props: any) => {
        const joinedColumns = props.dataItem?.fileUnmask?.allColumnsSelected
          ? 'All'
          : props.dataItem?.fileUnmask?.fileUnmaskColumns
              .map((x: any) => x.columnName)
              .join(', ')

        return <td>{joinedColumns}</td>
      },
    },
  ]

  const gridColumnsEdit: any[] = [
    {
      field: 'headerName',
      title: 'Column Name',
      width: 180,
      show: true,
    },
    {
      title: 'Unmasked',
      width: 180,
      show: true,
      render: (props: any) =>
        renderCheckbox(
          unmaksEntireFile,
          checkUnmaskedChecked(props.dataItem?.headerName),
          (checked) =>
            handleOnChangeUnmasked(checked, props.dataItem?.headerName)
        ),
    },
  ]

  const reactQueryClient = useQueryClient()
  const notifySuccess = notistackOptions('success')
  const notifyError = notistackOptions('error')

  const checkUnmaskedChecked = (columnName: string): boolean => {
    return (
      columnsRequest.some((x) => x.unmask && x.columnName === columnName) ||
      (!!fileUnmaskData?.sellerUploadTemplateFileUnmask.some(
        (template) =>
          template.sellerUploadTemplateId === editTemplateId &&
          template.fileUnmask?.fileUnmaskColumns?.some(
            (column) => column.columnName === columnName
          )
      ) &&
        !columnsRequest.some((x) => !x.unmask && x.columnName === columnName))
    )
  }

  const handleOnChangeUnmasked = (checked: boolean, columnName: string) => {
    setColumnsRequest((prev) => {
      const columnExists = prev.some((item) => item.columnName === columnName)

      if (columnExists) {
        return prev.map((item) =>
          item.columnName === columnName ? { ...item, unmask: checked } : item
        )
      } else {
        return [...prev, { columnName, unmask: checked }]
      }
    })
  }

  const handleSaveEditUnmask = () => {
    if (sellerId && editTemplateId) {
      setUnmaskWithColumnsRequest({
        sellerId: sellerId,
        sellerUploadTemplateId: editTemplateId,
        allColumnsSelected: unmaksEntireFile,
        unmaskColumnsRequest: columnsRequest,
      })
    }
  }

  const { data: fileUnmaskData, isFetching: fileUnmaskDataLoading } =
    useCustomQuery<SellerUploadTemplateFileUnmaskDataResponse>(
      ['getSellerUploadTemplateFileUnmaskBySeller', sellerId, gridState],
      async () => {
        return getSellerUploadTemplateFileUnmaskBySeller(
          sellerId,
          JSON.stringify(gridState)
        )
      },
      {
        enabled: !!sellerId,
        cacheTime: 0,
      }
    )

  const { data: portfolioTypes, isFetching: portfolioTypesLoading } =
    useCustomQuery<PortfolioType[]>(
      ['getAllPortfolioTypes'],
      async () => {
        return getAllPortfolioTypes()
      },
      {
        enabled: !!sellerId,
        cacheTime: 0,
      }
    )

  const { data: exportFileDefinitions, isFetching: fileDefinitionsLoading } =
    useCustomQuery<ExportFileDefinition[]>(
      ['getFileDefinitionByPortfolioType', portfolioTypeId],
      async () => {
        return getFileDefinitionByPortfolioType(portfolioTypeId)
      },
      {
        enabled: !!sellerId,
        cacheTime: 0,
      }
    )

  const { isFetching: addOrRemoveFileUnmaskLoading } = useCustomQuery(
    ['addOrRemoveFileUnmaskWithColumns', unmaskWithColumnsRequest],
    async () => {
      if (unmaskWithColumnsRequest) {
        return addOrRemoveFileUnmaskWithColumns(unmaskWithColumnsRequest)
          .then(() => {
            enqueueSnackbar('Unmasked columns updated.', notifySuccess)
            setOpenEditModal(false)
            reactQueryClient.refetchQueries({
              queryKey: ['getSellerUploadTemplateFileUnmaskBySeller'],
            })
          })
          .catch(() => {
            enqueueSnackbar('Error when update unmasked columns.', notifyError)
          })
          .finally(() => {
            setColumnsRequest([])
          })
      }
    },
    {
      enabled: !!unmaskWithColumnsRequest,
      cacheTime: 0,
    }
  )

  const { data: templateFileMap, isFetching: templateFileMapLoading } =
    useCustomQuery<PortfolioTemplateFileMapResponse>(
      ['getPortfolioTemplateFileMap', editTemplateId, openEditModal],
      async () => {
        return getPortfolioTemplateFileMap(editTemplateId)
      },
      {
        enabled: !!editTemplateId && openEditModal,
        cacheTime: 0,
      }
    )

  const handleSelectPortfolioType = (event: any) => {
    setPortfolioTypeId(event?.target?.value)
  }

  const handleSelectFileDefinition = (event: any) => {
    setFileDefinitionId(event?.target?.value)
  }

  return (
    <Box>
      <Box mb={6}>
        <Typography variant="h6">Portfolio Data Manager</Typography>
        <Grid mt={2} container spacing={4}>
          <Grid item xs={12} lg={12}>
            <DataTable
              style={{
                cursor: 'pointer',
              }}
              isLoading={fileUnmaskDataLoading}
              isFetching={fileUnmaskDataLoading}
              gridColumns={gridColumns}
              gridState={gridState}
              data={fileUnmaskData?.sellerUploadTemplateFileUnmask}
              sortable
              pageable
              onRowClick={(row) => {
                setEditTemplateId(row.dataItem.sellerUploadTemplateId)
                setUnmaksEntireFile(
                  row.dataItem?.fileUnmask?.allColumnsSelected
                )
                setOpenEditModal(true)
              }}
              total={fileUnmaskData?.total}
              onDataStateChange={(e: any) => {
                setGridState(e.dataState)
              }}
            />
          </Grid>
        </Grid>
        <ModalDialog
          isOpen={openEditModal}
          header="Edit Portfolio Data Manager"
          buttonOkText="Save"
          disableOkButton={
            columnsRequest.length === 0 &&
            unmaksEntireFile ===
              fileUnmaskData?.sellerUploadTemplateFileUnmask.find(
                (x) => x.sellerUploadTemplateId === editTemplateId
              )?.fileUnmask?.allColumnsSelected
          }
          onClose={() => {
            setOpenEditModal(false)
            setColumnsRequest([])
          }}
          isFetching={addOrRemoveFileUnmaskLoading}
          isLoading={addOrRemoveFileUnmaskLoading}
          onContinue={handleSaveEditUnmask}
          width="80%"
        >
          <Box display="flex" flexDirection="column" gap={2}>
            {!templateFileMapLoading ? (
              <Box display="flex" width="100%" justifyContent="flex-end">
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      onChange={() => setUnmaksEntireFile(!unmaksEntireFile)}
                      name="unmaskEntireFile"
                      checked={unmaksEntireFile}
                      value={unmaksEntireFile}
                    />
                  }
                  label="Unmask the entire file"
                />
              </Box>
            ) : (
              <Skeleton style={{ marginTop: '-2px' }} height={40} width={150} />
            )}
            <DataTable
              isLoading={templateFileMapLoading}
              isFetching={templateFileMapLoading}
              gridColumns={gridColumnsEdit}
              gridState={gridStateEdit}
              data={templateFileMap?.columns}
              total={fileUnmaskData?.total}
            />
          </Box>
        </ModalDialog>
      </Box>
      <Box mb={6}>
        <Typography variant="h6">Validation Manager</Typography>
        <Grid container direction="row" style={{ gap: 10 }}>
          <Grid item>
            <TextField
              select
              label="Portfolio Type"
              onChange={handleSelectPortfolioType}
              value={portfolioTypeId}
              style={{
                alignSelf: 'flex-start',
                width: '200px',
              }}
            >
              {portfolioTypes?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.typeName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item>
            <TextField
              select
              label="File Map"
              onChange={handleSelectFileDefinition}
              value={fileDefinitionId}
              style={{
                alignSelf: 'flex-start',
                width: '200px',
              }}
            >
              {exportFileDefinitions?.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default FileUnmaskList
