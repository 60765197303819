import React, { useState } from 'react'
import { Box, Grid, IconButton } from '@mui/material'
import { Archive } from '@mui/icons-material'
import { State } from '@progress/kendo-data-query'
import { renderListJoined } from 'src/utils/formatKendoColumns'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import {
  Button,
  Colors,
  DataTable,
  ModalDialog,
  Typography,
} from 'everchain-uilibrary'
import { useHistory } from 'react-router-dom'
import { MEDIA_NAMING_CONVENTION_FORM } from 'src/routes'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { notistackOptions } from 'src/configs/notistackOptions'
import { enqueueSnackbar } from 'notistack'
import {
  deleteMediaNamingConvention,
  getMediaNamingConventions,
} from 'src/infra/api/services/mediaNamingConvention'
import { MediaNamingConventionResponseType } from 'src/infra/api/models/mediaNamingConvention'
interface MediaNamingConventionListProps {
  sellerId?: string
  clientId?: number
  tabSelected?: string
}

const MediaNamingConventionList = ({
  sellerId,
  clientId,
  tabSelected,
}: MediaNamingConventionListProps) => {
  const [totalDataSize, setTotalDataSize] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [currentItem, setCurrentItem] = useState<number>(0)
  const [openConfirmationModal, setOpenConfirmationModal] =
    useState<boolean>(false)

  const [gridState, setGridState] = useState<State>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  })
  const gridColumns: any[] = [
    {
      field: 'name',
      title: 'Name',
      width: 75,
      show: true,
    },
    {
      field: 'assetTypes',
      title: 'Assigned Asset Type',
      width: 300,
      show: true,
      render: (props: any) => {
        return renderListJoined(props.dataItem.assetTypes, false, 'All')
      },
    },
    {
      field: 'namingConvention',
      title: 'Naming Convention',
      width: 125,
      show: true,
    },
    {
      field: '',
      title: 'Actions',
      width: 45,
      show: true,
      render: (props: any) => (
        <td>
          {!props.dataItem.archivedDate ? (
            <center>
              <IconButton
                onClick={() => {
                  setCurrentItem(props.dataItem.id)
                  setOpenConfirmationModal(true)
                }}
                size="small"
              >
                <Archive />
              </IconButton>
            </center>
          ) : (
            <span>
              <center>Deleted</center>
            </span>
          )}
        </td>
      ),
    },
  ]
  const { data: mediaNamingData, isFetching: mediaNamingConventionLoading } =
    useCustomQuery<MediaNamingConventionResponseType>(
      ['getMediaNamingConventions', gridState],
      async () => {
        return getMediaNamingConventions(
          sellerId,
          JSON.stringify(gridState)
        ).then((result: MediaNamingConventionResponseType) => {
          setTotalDataSize(result.total)
          return result
        })
      },
      {
        cacheTime: 0,
      }
    )

  const history = useHistory()
  const notifySuccess = notistackOptions('success')
  const notifyError = notistackOptions('error')
  const reactQueryClient = useQueryClient()

  const deleteMediaNamingConventionMutation = useMutation({
    mutationFn: (request: any) => deleteMediaNamingConvention(request.id),
    onSuccess: () => {
      setCurrentItem(0)
      setIsLoading(false)
      setOpenConfirmationModal(false)
      enqueueSnackbar(
        'Media Naming Convention removed successfully.',
        notifySuccess
      )
      reactQueryClient.refetchQueries({
        queryKey: ['getMediaNamingConventions'],
      })
    },
    onError: () => {
      setCurrentItem(0)
      setOpenConfirmationModal(false)
      setIsLoading(false)
      enqueueSnackbar(
        'Error when removing Media Naming Convention.',
        notifyError
      )
    },
  })

  return (
    <Box mb={12}>
      <Typography variant="h6">Media Naming Convention</Typography>
      <ModalDialog
        header="Confirmation"
        isOpen={openConfirmationModal}
        onClose={() => {
          setOpenConfirmationModal(false)
        }}
        isLoading={isLoading || mediaNamingConventionLoading}
        buttonOkText="Submit"
        buttonCancelText="Cancel"
        disableCancelButton={isLoading || mediaNamingConventionLoading}
        disableOkButton={isLoading || mediaNamingConventionLoading}
        onContinue={() => {
          setIsLoading(false)
          setOpenConfirmationModal(false)
          deleteMediaNamingConventionMutation.mutate({
            id: currentItem,
          })
        }}
      >
        <Typography
          color={Colors.primary}
          variant="caption"
          style={{ fontSize: 16 }}
          isLoading={isLoading || mediaNamingConventionLoading}
        >
          Are you sure you want to delete this media naming convention?
        </Typography>
      </ModalDialog>
      <Grid mt={2} container spacing={4}>
        <Grid
          item
          xs={12}
          lg={12}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <Button
            onClick={() => {
              history.push(
                `${MEDIA_NAMING_CONVENTION_FORM}/${sellerId}/${clientId}/${tabSelected}`
              )
            }}
            height={40}
            width={100}
          >
            New
          </Button>
        </Grid>
        <Grid item xs={12} lg={12}>
          <DataTable
            style={{
              cursor: 'pointer',
            }}
            isLoading={mediaNamingConventionLoading || isLoading}
            isFetching={mediaNamingConventionLoading || isLoading}
            gridColumns={gridColumns}
            gridState={gridState}
            data={mediaNamingData?.data}
            sortable
            pageable
            total={totalDataSize}
            onDataStateChange={(e: any) => {
              setGridState(e.dataState)
            }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

MediaNamingConventionList.defaultProps = {
  sellerId: null,
}
export default MediaNamingConventionList
