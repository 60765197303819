import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Switch,
  TextField,
  Tooltip,
  Typography,
  Skeleton,
} from '@mui/material'
import { Save, PersonAdd, LockOpen, Block, Info } from '@mui/icons-material'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import { useHistory, useParams } from 'react-router-dom'
import React, { useContext, useEffect, useState } from 'react'
import {
  useSendUserInvite,
  useUnlockUser,
} from 'src/graphql/operations/mutations/clientProfiles'
import * as Yup from 'yup'
import hardCodeData from 'src/utils/hardcodeData'
import { maskPhoneNumber, unmaskPhoneNumber } from 'src/utils/masker'
import {
  GetClient,
  GetUserDetail,
  User as UserModel,
} from 'src/graphql/models/clientProfiles'
import { mapPropNullToString } from 'src/utils/common'
import { useLazyQuery, useQuery } from '@apollo/client'
import {
  GET_CLIENT,
  GET_USER_DETAIL,
} from 'src/graphql/operations/queries/clientProfiles'

import Header from 'src/components/Header'
import { AbilityContext } from 'src/context/Can'
import { PermissionCodeAccess, TENANT_TYPE } from 'src/utils/constants'
import { AuthContext } from 'src/context/AuthenticationContext'
import { CLIENT_PROFILES_MANAGE, INTERNAL_USERS } from 'src/routes'
import { GET_ALL_ROLES } from 'src/graphql/operations/queries/managePermissions'
import { formatDateAndTime } from 'src/utils/date'
import { useMutation } from '@tanstack/react-query'
import { createUsers } from 'src/infra/api/services/user'

const userSchema = Yup.object().shape({
  status: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  firstName: Yup.string().required('Required'),
  displayName: Yup.string().required('Required'),
  title: Yup.string().required('Required'),
  emailAddress: Yup.string()
    .nullable()
    .required('Required')
    .email('Invalid email'),
})

const User = () => {
  const { tenantId, clientId, userId } = useParams<any>()
  const ability = useContext(AbilityContext)
  const { user } = useContext(AuthContext)

  const isInternal = user && user.profile[TENANT_TYPE] === 'internal'

  const [canEdit, setCanEdit] = useState(false)

  const [canInviteUser, setCanInviteUser] = useState(false)

  const history = useHistory()
  const { data: getClientData } = useQuery<{
    getClient: GetClient
  }>(GET_CLIENT, { variables: { clientId: Number(tenantId) } })
  const country =
    getClientData?.getClient?.countryCode ?? process.env.REACT_APP_COUNTRY

  const initialValueData = {
    status: '',
    lastName: '',
    displayName: '',
    enableTextMessaging: false,
    emailAddress: '',
    isSales: false,
    isCustomerSuccess: false,
    firstName: '',
    title: '',
    voicePhoneNumber: '',
    textMessagingPhoneNumber: '',
    profilePictureUrl: '',
    isTFAEnabled: false,
  }

  const [initialValues, setInitialValues] = useState<any>(initialValueData)

  const { enqueueSnackbar } = useSnackbar()

  const handleSavedUserCompleted = (data: any) => {
    if (data) {
      enqueueSnackbar('User saved successfully', {
        variant: 'success',
      })
      if (userId == null || userId === undefined) {
        history.push(returnUrl)
      }
    }
  }

  const handleSaveUserFailed = (error: any) => {
    let errorMessage =
      'An error occurred while saving the user info. Please, contact our support.'
    const e = error as string
    if (e.indexOf('The email') > -1 && e.indexOf('already exists') > -1)
      errorMessage = e.replace('GraphQL.ExecutionError: ', '')

    enqueueSnackbar(errorMessage, {
      variant: 'error',
    })
  }

  const getRoleQueryVariables = (pUserId: string): {} => {
    return {
      roleListRequest: {
        userIdFilter: pUserId,
      },
    }
  }

  const [getRoles, { data: dataRoles, loading: loadingRoles }] =
    useLazyQuery<any>(GET_ALL_ROLES, {
      fetchPolicy: 'cache-and-network',
      variables: getRoleQueryVariables(userId),
    })

  const sendUserInviteOnCompleted = (data: any) => {
    if (data) {
      enqueueSnackbar('User invited', {
        variant: 'success',
      })
    }
  }

  const sendUnlockOnCompleted = (data: any) => {
    if (data) {
      enqueueSnackbar('User unlocked', {
        variant: 'success',
      })
    }
  }
  const { SendUserInvite } = useSendUserInvite(sendUserInviteOnCompleted)
  const { UnlockUser, loading: loadingUnlock } = useUnlockUser(
    sendUnlockOnCompleted
  )

  const handleUnlockUser = (id: string) => {
    UnlockUser({
      variables: {
        userId,
      },
      refetchQueries: ['GetUserDetail'],
    })
  }
  const handleSendUserInvite = (id: string) => {
    SendUserInvite({
      variables: {
        id,
      },
    })
  }

  const [getUserDetail, { data: userData, loading: loadingUserData, refetch }] =
    useLazyQuery<GetUserDetail>(GET_USER_DETAIL, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
    })

  const mutationCreateUsers = useMutation({
    mutationFn: async (users: string) => {
      const result = await createUsers('', users)

      if (result.indexOf('already exists') > -1) {
        handleSaveUserFailed(result)
      } else {
        handleSavedUserCompleted(result)
      }

      refetch()
    },
  })

  const userForm = useFormik({
    initialValues,
    validationSchema: userSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      const userRequest = {
        status: values.status || null,
        lastName: values.lastName || null,
        displayName: values.displayName || null,
        enableTextMessaging: values.enableTextMessaging || false,
        emailAddress: values.emailAddress || null,
        isSales: values.isSales || false,
        isCompliance: values.isCompliance || false,
        isCustomerSuccess: values.isCustomerSuccess || false,
        firstName: values.firstName || null,
        title: values.title || null,
        voicePhoneNumber: values.voicePhoneNumber
          ? unmaskPhoneNumber(values.voicePhoneNumber, country)
          : null,
        textMessagingPhoneNumber: values.textMessagingPhoneNumber || null,
        profilePictureUrl: values.profilePictureUrl || null,
        userType: 'control-panel-user',
        id: userData?.getUserDetail?.id,
        tenantId: userData?.getUserDetail?.tenantId ?? tenantId,
        isTFAEnabled: values.isTFAEnabled || false,
      }

      const users: any[] = []

      users.push(userRequest)

      mutationCreateUsers.mutate(JSON.stringify(users))

      // refetchQueries: ['GetUserDetail'],
      setSubmitting(false)
    },
  })
  const editingInternalUser =
    // eslint-disable-next-line eqeqeq
    tenantId === 'ADE44E98-9ED6-4AF6-916A-3A21FBED7351' || tenantId == 0

  useEffect(() => {
    if (
      (editingInternalUser &&
        ability.can(
          PermissionCodeAccess.CLIENT_INTERNAL_USER_PERMISSION,
          'any'
        )) ||
      (!editingInternalUser &&
        ability.can(
          PermissionCodeAccess.CLIENT_EXTERNAL_USER_PERMISSION,
          'any'
        ))
    ) {
      setCanEdit(true)
    } else setCanEdit(false)
  }, [ability, editingInternalUser])

  useEffect(() => {
    if (userId) {
      if (!loadingUserData && userData) {
        setInitialValues((prevState: UserModel) => ({
          ...prevState,
          ...mapPropNullToString(userData.getUserDetail),
          voicePhoneNumber: userData.getUserDetail.voicePhoneNumber
            ? maskPhoneNumber(userData.getUserDetail.voicePhoneNumber, country)
            : null,
        }))
      } else {
        getRoles()
        getUserDetail({
          variables: {
            userId,
          },
        })
      }
    }
  }, [userData, userId, loadingUserData, getUserDetail, getRoles, country])

  useEffect(() => {
    if (!loadingRoles && dataRoles && userData) {
      if (
        dataRoles.getAllRoles.totalCount > 0 &&
        !userData.getUserDetail.hasPassword
      ) {
        setCanInviteUser(true)
      }
    }
  }, [loadingRoles, dataRoles, userData])

  const returnUrl =
    clientId > 0
      ? `${CLIENT_PROFILES_MANAGE}/${clientId}/2`
      : `${INTERNAL_USERS}`

  if (loadingUserData || loadingRoles) {
    return <Skeleton variant="rectangular" height={150} width="100%" />
  }

  return (
    <div>
      <form onSubmit={userForm.handleSubmit}>
        <Box>
          <Box p={5}>
            <Header
              ignoreFirstName={true}
              title={
                userData?.getUserDetail.displayName
                  ? `Editing ${userData?.getUserDetail.displayName}`
                  : 'Create New User'
              }
              customBackUrl={returnUrl}
            />
          </Box>
          <Box p={5}>
            <Paper>
              <Box p={5}>
                <Box mb={6}>
                  <Typography variant="h6">User Info</Typography>
                  <Divider />
                </Box>
                <Box mb={6}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} lg={3}>
                      <TextField
                        fullWidth
                        label="Status"
                        name="status"
                        select
                        onChange={userForm.handleChange}
                        error={!!userForm.errors.status}
                        value={userForm.values.status}
                        helperText={<>{userForm.errors.status}</>}
                      >
                        {hardCodeData.getAlternativeStatus().map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} lg={3}>
                      <TextField
                        fullWidth
                        label="Display Name"
                        name="displayName"
                        value={userForm.values.displayName}
                        onChange={userForm.handleChange}
                        error={!!userForm.errors.displayName}
                        helperText={<>{userForm.errors.displayName}</>}
                      />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                      <TextField
                        fullWidth
                        label="First Name"
                        name="firstName"
                        value={userForm.values.firstName}
                        onChange={userForm.handleChange}
                        error={!!userForm.errors.firstName}
                        helperText={<>{userForm.errors.firstName}</>}
                      />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                      <TextField
                        fullWidth
                        label="Last Name"
                        name="lastName"
                        value={userForm.values.lastName}
                        onChange={userForm.handleChange}
                        error={!!userForm.errors.lastName}
                        helperText={<>{userForm.errors.lastName}</>}
                      />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                      <TextField
                        fullWidth
                        label="Title"
                        name="title"
                        value={userForm.values.title}
                        onChange={userForm.handleChange}
                        error={!!userForm.errors.title}
                        helperText={<>{userForm.errors.title}</>}
                      />
                    </Grid>
                    {userData?.getUserDetail && (
                      <Grid item xs={12} lg={3}>
                        <Typography>
                          Last password change:{' '}
                          {formatDateAndTime(
                            userData?.getUserDetail?.passwordChangeDate
                          )}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Box>
                {isInternal && editingInternalUser && (
                  <Box mb={6}>
                    <Grid container spacing={4}>
                      <Grid item xs={12} lg={3}>
                        <FormControlLabel
                          control={
                            <Switch
                              color="primary"
                              onChange={userForm.handleChange}
                              name="isSales"
                              checked={userForm.values.isSales}
                              value={userForm.values.isSales}
                            />
                          }
                          label="Sales"
                        />
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <FormControlLabel
                          control={
                            <Switch
                              color="primary"
                              onChange={userForm.handleChange}
                              name="isCustomerSuccess"
                              checked={userForm.values.isCustomerSuccess}
                              value={userForm.values.isCustomerSuccess}
                            />
                          }
                          label="Customer Success"
                        />
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <FormControlLabel
                          control={
                            <Switch
                              color="primary"
                              onChange={userForm.handleChange}
                              name="isCompliance"
                              checked={userForm.values.isCompliance}
                              value={userForm.values.isCompliance}
                            />
                          }
                          label="Compliance"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                )}
                <Box mb={6}>
                  <Typography variant="h6">Contact</Typography>
                  <Divider />
                </Box>
                <Box mb={6}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} lg={3}>
                      <FormControlLabel
                        control={
                          <Switch
                            color="primary"
                            onChange={userForm.handleChange}
                            name="enableTextMessaging"
                            checked={userForm.values.enableTextMessaging}
                            value={userForm.values.enableTextMessaging}
                          />
                        }
                        label="Enable Text Messaging"
                      />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                      <TextField
                        fullWidth
                        label="Voice Phone Number"
                        name="voicePhoneNumber"
                        value={userForm.values.voicePhoneNumber}
                        inputProps={{ maxlength: 20 }}
                        onChange={({ target: { value, name } }) => {
                          const masked = maskPhoneNumber(value, country)
                          userForm.setFieldValue(name, masked, false)
                        }}
                        error={!!userForm.errors.voicePhoneNumber}
                        helperText={<>{userForm.errors.voicePhoneNumber}</>}
                      />
                    </Grid>

                    <Grid item xs={12} lg={3}>
                      <TextField
                        fullWidth
                        label="Text Messaging Phone Number"
                        name="textMessagingPhoneNumber"
                        inputProps={{ maxlength: 20 }}
                        value={userForm.values.textMessagingPhoneNumber}
                        onChange={({ target: { value, name } }) => {
                          const masked = maskPhoneNumber(value, country)
                          userForm.setFieldValue(name, masked, false)
                        }}
                        error={!!userForm.errors.textMessagingPhoneNumber}
                        helperText={
                          <>{userForm.errors.textMessagingPhoneNumber}</>
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box mb={6}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} lg={3}>
                      <TextField
                        fullWidth
                        label="Email Address"
                        name="emailAddress"
                        value={userForm.values.emailAddress}
                        onChange={userForm.handleChange}
                        error={!!userForm.errors.emailAddress}
                        helperText={<>{userForm.errors.emailAddress}</>}
                      />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                      <TextField
                        fullWidth
                        label="Profile Picture Url"
                        name="profilePictureUrl"
                        value={userForm.values.profilePictureUrl}
                        onChange={userForm.handleChange}
                        error={!!userForm.errors.profilePictureUrl}
                        helperText={<>{userForm.errors.profilePictureUrl}</>}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box mb={6}>
                  <Typography variant="h6">
                    Two-Factor Authentication
                  </Typography>
                  <Divider />
                </Box>
                <Box mb={6}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} lg={3}>
                      <FormControlLabel
                        control={
                          <Switch
                            color="primary"
                            onChange={userForm.handleChange}
                            name="isTFAEnabled"
                            checked={userForm.values.isTFAEnabled}
                            value={userForm.values.isTFAEnabled}
                          />
                        }
                        label="Enable Two-Factor Authentication"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box display="flex" justifyContent="flex-end" p={6}>
                {!userData?.getUserDetail.lockoutEnd &&
                  canInviteUser &&
                  !editingInternalUser &&
                  userId && (
                    <>
                      <Tooltip
                        title="Must add roles to the user to send an invite."
                        aria-label="filter"
                      >
                        <IconButton
                          aria-label="refresh"
                          aria-controls="action-refresh"
                          aria-haspopup="true"
                          color="primary"
                          size="small"
                        >
                          <Info />
                        </IconButton>
                      </Tooltip>
                      <Button
                        style={{ marginRight: '5px' }}
                        variant="contained"
                        color="primary"
                        disabled={!canInviteUser}
                        onClick={(): void => {
                          handleSendUserInvite(userId)
                        }}
                        startIcon={
                          mutationCreateUsers.isLoading ? (
                            <CircularProgress size={16} />
                          ) : (
                            <PersonAdd />
                          )
                        }
                      >
                        Invite User
                      </Button>
                    </>
                  )}
                {userData?.getUserDetail.lockoutEnd && !editingInternalUser && (
                  <Button
                    style={{ marginRight: '5px' }}
                    variant="contained"
                    color="primary"
                    disabled={loadingUnlock}
                    onClick={(): void => {
                      handleUnlockUser(userId)
                    }}
                    startIcon={
                      loadingUnlock ? (
                        <CircularProgress size={16} />
                      ) : (
                        <LockOpen />
                      )
                    }
                  >
                    Unlock User
                  </Button>
                )}
                <Button
                  style={{ marginRight: '5px' }}
                  color="primary"
                  variant="outlined"
                  startIcon={
                    loadingUserData ? (
                      <CircularProgress size={15} color="primary" />
                    ) : (
                      <Block fontSize="small" />
                    )
                  }
                  onClick={(): void => {
                    history.push(returnUrl)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!canEdit || !userForm.dirty}
                  startIcon={
                    mutationCreateUsers.isLoading ? (
                      <CircularProgress size={16} style={{ color: 'white' }} />
                    ) : (
                      <Save />
                    )
                  }
                >
                  Save
                </Button>
              </Box>
            </Paper>
          </Box>
        </Box>
      </form>
    </div>
  )
}

export default User
