import React, { useContext } from 'react'
import TransferList from 'src/components/TransferList'
import {
  BusinessBuyerRequestType,
  PortfolioType,
} from 'src/graphql/models/buyer'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import {
  useAddBuyerPortfolioType,
  useRemoveBuyerPortfolioType,
} from 'src/graphql/operations/mutations/businesses'
import { AbilityContext } from 'src/context/Can'
import { PermissionCodeAccess } from 'src/utils/constants'

interface BuyerPortoflioTypesProps {
  buyerData: BusinessBuyerRequestType | undefined
}

const BuyerPortoflioTypes = ({ buyerData }: BuyerPortoflioTypesProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const ability = useContext(AbilityContext)
  const notistackSucces = notistackOptions('success')
  const notistackWarning = notistackOptions('warning')

  const mapItems = (array: PortfolioType[]) =>
    array.map((e) => ({ id: e.id, name: e.typeName }))

  const { addBuyerPortfolioType } = useAddBuyerPortfolioType((data: any) => {
    if (data) {
      enqueueSnackbar('Portfolio types updated successfully', notistackSucces)
    } else {
      enqueueSnackbar(
        'Error while trying to update the portfolio types',
        notistackWarning
      )
    }
  })

  const { removeBuyerPortfolioType } = useRemoveBuyerPortfolioType(
    (data: any) => {
      if (data) {
        enqueueSnackbar('Portfolio types updated successfully', notistackSucces)
      } else {
        enqueueSnackbar(
          'Error while trying to update the portfolio types',
          notistackWarning
        )
      }
    }
  )

  const handleSendLeftColumn = (data: any[]) => {
    const ids = data.map((e) => e.id)
    removeBuyerPortfolioType({
      variables: {
        request: {
          buyerId: buyerData?.id,
          portfolioTypeId: ids,
        },
      },
      refetchQueries: ['GetBuyer'],
    })
  }

  const handleSendRightColumn = (data: any[]) => {
    const ids = data.map((e) => e.id)
    addBuyerPortfolioType({
      variables: {
        request: {
          buyerId: buyerData?.id,
          portfolioTypeId: ids,
        },
      },
      refetchQueries: ['GetBuyer'],
    })
  }

  return (
    <TransferList
      loading={false}
      leftData={mapItems(
        buyerData?.buyerPortfolioType?.availablePortfolioTypes || []
      )}
      rightData={mapItems(
        buyerData?.buyerPortfolioType?.buyerPortfolioTypes || []
      )}
      onSendLeftColumn={handleSendLeftColumn}
      onSendRightColumn={handleSendRightColumn}
      readonly={
        !ability.can(PermissionCodeAccess.CLIENT_BASICINFO_PERMISSION, 'any')
      }
    />
  )
}

export default BuyerPortoflioTypes
