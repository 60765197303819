import { gql } from '@apollo/client'

export const GET_NOTIFICATION_SUBSCRIPTION = gql`
  query GetNotificationSubscription($filters: BusinessNotificationFilters!) {
    getNotificationSubscription(filters: $filters) {
      businessId
      emailAddress
      userDisplayName
      userId
      userEmailAddress
    }
  }
`

export const GET_BUSINESS_NOTIFICATION_EVENT_LIST = gql`
  query GetBusinessNotificationEventList($businessId: String!) {
    getBusinessNotificationEventList(businessId: $businessId) {
      eventLabel
      eventSection
      eventType
      eventTypeDescription
      id
      subscribed
    }
  }
`

export const GET_NOTIFICATION_SUMMARY = gql`
  query GetNotificationSummary($businessId: String) {
    getNotificationSummary(businessId: $businessId) {
      listEmails
      listUsers {
        id
        displayName
        emailAddress
        enableTextMessaging
        firstName
        lastName
        lastUpdated
        lastUpdatedUserId
        lastUpdatedUserName
        tenantId
        title
        status
        textMessagingPhoneNumber
      }
    }
  }
`

export const GET_NOTIFICATION_DETAIL = gql`
  query GetNotificationDetail($request: BusinessNotificationDetailRequest!) {
    getNotificationDetail(request: $request) {
      eventLabel
      eventSection
      eventType
      eventTypeDescription
      subscribed
    }
  }
`

export const GET_BUSINESS_LICENSES = gql`
  query GetBusinessLicenses($businessId: String!, $pagination: DataPagination) {
    getBusinessLicenses(businessId: $businessId, pagination: $pagination) {
      total
      licenseResponse {
        businessId
        city
        comment
        countryCode
        expirationDate
        issuedDate
        id
        stateCode
        lastUpdatedByUserId
        lastUpdatedByUserName
        lastUpdatedUTCDate
      }
    }
  }
`

export const GET_BUSINESS_RECOVERY = gql`
  query GetBusinessRecoveries($request: BusinessRecoveryRequest!) {
    businessRecoveries: getBusinessRecoveries(request: $request) {
      id
      name
      category
      street1
      street2
      city
      stateCode
      postalCode
      primaryContact_LastName
      primaryContact_FirstName
      primaryContact_EMail
      primaryContact_OfficePhone
      primaryContact_MobilePhone
      businessRecoverySettings {
        contactLastName
        contactFirstName
        contactEmail
        contactOfficePhone
        contactMobilePhone
        isVendor
        isCreditor
      }
      hoursOfOperation
      bankName
      bankStreet1
      bankStreet2
      bankCity
      bankStateCode
      bankPostalCode
      bankPhone
      accountNumber
      routingNumber
      nameOnAccount
      assetTypes {
        id
        name
      }
      deliveryStructures {
        id
        name
      }
      operationalStructures {
        id
        name
      }
    }
  }
`
export const GET_BUSINESS_RECOVERY_SETTINGS = gql`
  query GetBusinessRecoverySettings($businessId: String!) {
    businessRecoverySettings: getBusinessRecoverySettings(
      businessId: $businessId
    ) {
      id
      businessId
      contactLastName
      contactFirstName
      contactEmail
      contactOfficePhone
      contactMobilePhone
      isVendor
      isCreditor
    }
  }
`

export const GET_DELIVERY_STRUCTURES = gql`
  query GetDeliveryStructures($businessId: String!) {
    deliveryStructures: getDeliveryStructures(businessId: $businessId) {
      availableDeliveryStructures {
        id
        name
      }
      deliveryStructures {
        id
        name
      }
    }
  }
`

export const GET_OPERATIONAL_STRUCTURES = gql`
  query GetOperationalStructures($businessId: String!) {
    operationalStructures: getOperationalStructures(businessId: $businessId) {
      availableOperationalStructures {
        id
        name
      }
      operationalStructures {
        id
        name
      }
    }
  }
`

export const GET_ASSET_TYPES = gql`
  query GetBusinessAssetTypes($businessId: String!) {
    businessAssetTypes: getBusinessAssetTypes(businessId: $businessId) {
      availableAssetTypes {
        id
        name
      }
      assetTypes {
        id
        name
      }
    }
  }
`

export const GET_BUSINESSES = gql`
  query GetBusinesses($businessListRequest: BusinessListRequest!) {
    getBusinesses(businessListRequest: $businessListRequest) {
      businesses {
        id
        name
        status
        type: businessType
        buyerType
      }
    }
  }
`

export const GET_INTERNAL_NOTIFICATION_RECIPIENTS = gql`
  query GetInternalNotificationRecipients {
    getInternalNotificationRecipients {
      listEmails
      listUsers {
        id
        displayName
        emailAddress
        enableTextMessaging
        firstName
        lastName
        lastUpdated
        lastUpdatedUserId
        lastUpdatedUserName
        tenantId
        title
        status
        textMessagingPhoneNumber
      }
    }
  }
`

export const GET_INTERNAL_NOTIFICATION_DETAIL = gql`
  query GetInternalNotificationDetail(
    $request: BusinessInternalNotificationDetailRequest!
  ) {
    getInternalNotificationDetail(request: $request) {
      eventLabel
      eventSection
      eventType
      eventTypeDescription
      subscribed
    }
  }
`

export const GET_OPERATING_PLACEMENT_STAGE = gql`
  query GetBusinessOperatingPlacementStage($businessId: String!) {
    businessOperatingPlacementStage: getBusinessOperatingPlacementStage(
      businessId: $businessId
    ) {
      availableOperatingPlacementStages
      operatingPlacementStages
    }
  }
`

export const GET_ASSET_TYPES_BY_OPERATING_PLACEMENT_STAGE = gql`
  query GetBusinessAssetTypesByBusinessOperatingPlacementStage(
    $businessId: String!
    $businessOperatingPlacementStage: String!
  ) {
    businessAssetTypes: getBusinessAssetTypesByBusinessOperatingPlacementStage(
      businessId: $businessId
      businessOperatingPlacementStage: $businessOperatingPlacementStage
    ) {
      availableAssetTypes {
        id
        name
        assetTypeFee
      }
      assetTypes {
        id
        name
        assetTypeFee
      }
    }
  }
`
